import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import check from 'utils/check';

const Can = ({ action, children, context = {}, no = false }) => {
  const { user, organization } = useSelector((state) => state.auth);

  if (!user || !organization || !user?.role) {
    return <Navigate to={'/login'} />;
  }

  const role = user.role;

  const hasPermission = check(role, action, { ...context, user });

  if (hasPermission) {
    return <>{children}</>;
  }

  if (!no) {
    return <></>;
  }
  return no;
};

export default Can;
