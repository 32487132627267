import {
  styled,
  Drawer,
  Divider,
  Grid
} from '@mui/material';

export const RootDiv = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.white,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(2), 
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 55,
      height: 'calc(100% - 55px)',
    },
    zIndex: '1300 !important',
  },
}));

export const StyledSidebar = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2), 
}));
