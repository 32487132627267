import { Navigate, useRoutes } from 'react-router-dom';

import AppLayout from 'layouts/AppLayout';
import AuthLayout from 'layouts/AuthLayout';
import Dashboard from 'components/app/Dashboard';
import Login from 'components/auth/Login';
import Logout from 'components/auth/Logout';
import PasswordReset from 'components/auth/EmailPasswordReset';
import Register from 'components/auth/Register';
import Reset from 'components/auth/PasswordReset';
import Admin from 'components/app/Admin';
import ClientForm from 'components/app/ClientForm';
import ProposalCart from 'components/app/ProposalCart';
import ProductForm from 'components/app/ProductForm';

const forwardToDashboard = () => (
  <Navigate to="/dashboard" push={false} search={false} />
);

const AppRoutes = () => {
  let routes = [
    {
      path: '/',
      element: forwardToDashboard(),
      exact: true,
    },
    {
      path: '/logout',
      element: <Logout />,
    },
    {
      path: '/login',
      element: <AuthLayout component={Login} />,
    },
    {
      path: '/password-reset',
      element: <AuthLayout component={PasswordReset} />,
    },
    {
      path: '/reset/:token',
      element: <AuthLayout component={Reset} />,
    },
    {
      path: '/register',
      element: <AuthLayout component={Register} />,
    },
    {
      path: '/dashboard',
      element: <AppLayout component={Dashboard} search={false} />,
    },
    {
      path: '/search/:filter',
      element: <AppLayout component={Dashboard} search={true} />,
    },
    {
      path: '/client-form',
      element: <AppLayout component={ClientForm} newProposal={true} />,
    },
    {
      path: '/client-form/:slug',
      element: <AppLayout component={ClientForm} />,
    },
    {
      path: '/cart/:slug',
      element: <AppLayout component={ProposalCart} />,
    },
    {
      path: '/product-form/:slug',
      element: <AppLayout component={ProductForm} />,
    },
    {
      path: '/settings',
      element: <AppLayout component={Admin} />,
    },
    {
      path: '',
      element: forwardToDashboard(),
    },
  ];

  let allRoutes = useRoutes(routes);

  return <>{allRoutes}</>;
};

export default AppRoutes;
