import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';

import Profile from './Profile';
import SidebarNav from './SidebarNav';
import {
  MobileBuild,
  RootDiv,
  StyledDivider,
  StyledDrawer,
  StyledProfile,
  StyledSidebar,
} from 'styles/layouts/AppLayout/SideBar.style';

import { Typography, Grid } from '@mui/material';

const Sidebar = ({ isDesktop, onClose, open, variant }) => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const [build] = useState(process.env.REACT_APP_BUILD);
  const anchor = isDesktop ? 'left' : 'right';

  return (
    <StyledDrawer
      anchor={anchor}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      {/* <RootDiv>
        <Profile />
        <StyledDivider />
        <SidebarNav onClose={onClose} />

        {
          isMobile
          ? <MobileBuild>
              <StyledDivider />
              <RootDiv>
                <Typography variant="body1">&copy; 2020</Typography>
                <Typography variant="body2">Build: {build}</Typography>
              </RootDiv>
            </MobileBuild>
          : <></>
        }
      </RootDiv> */}
      <StyledSidebar container direction="column" justifyContent="space-between">
        <Grid item>
          <Profile />
          <StyledDivider />
        </Grid>
        <Grid item>
          <SidebarNav onClose={onClose} />
        </Grid>
        <Grid item>
          {
            isMobile
            ? <>
                <StyledDivider />
                <RootDiv>
                  <Typography variant="body1">&copy; 2020</Typography>
                  <Typography variant="body2">Build: {build}</Typography>
                </RootDiv>
              </>
            : <></>
          }
        </Grid>
      </StyledSidebar>
    </StyledDrawer>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Sidebar.propTypes = {
    className: PropTypes.string,
    isDesktop: PropTypes.bool,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired,
  };
}

export default Sidebar;
