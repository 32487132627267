import { createRoot } from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

//Sentry React App Monitoring
Sentry.init({
  dsn: "https://ad2ac971b6854d539491cdb2521e1cc9@o4503903182323712.ingest.sentry.io/4505087201837056",
  //release: process.env.REACT_APP_BUILD,
  integrations: [
    new BrowserTracing(),
  ],

  tracesSampleRate: 1.0,
  autoSessionTracking: true,
});

const root = createRoot(document.getElementById('root'));

root.render(<App />);
