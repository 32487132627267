import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { Divider, Typography } from '@mui/material';
import RootDiv from '../../../styles/layouts/AppLayout/Footer.style';

const Footer = ({ className }) => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const [build] = useState(process.env.REACT_APP_BUILD);

  return (
    isMobile
    ? <></>
    : <RootDiv>
        <Divider />
        <Typography variant="body1">&copy; 2020</Typography>
        <Typography variant="body2">Build: {build}</Typography>
      </RootDiv>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Footer.propTypes = {
    className: PropTypes.string,
  };
}

export default Footer;
