import { ArrowForwardIos, Cancel } from '@mui/icons-material';
import {
  styled,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

export const ContainerDiv = styled('div')`
  width: 350px;
  max-width: 100%;
`;

export const Header = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  backgroundPositionX: 'right',
  backgroundPositionY: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  padding: theme.spacing(2, 1),
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary
}));

export const Content = styled('div')``;

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
}));

export const StyledListIcon = styled(ListItemIcon)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.03)',
  borderRadius: '50%',
  marginRight: theme.spacing(2),
  minWidth: 0,
  padding: '10px',
}));

export const CancelAction = styled(Cancel)(({ theme }) => ({
  color: theme.palette.text.secondary,
  height: '16px',
  width: '16px',
}));

export const ForwardAction = styled(ArrowForwardIos)(({ theme }) => ({
  color: theme.palette.text.secondary,
  height: '16px',
  width: '16px',
}));

export const Empty = styled('div')(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(3),
}))

export const StyledListText = styled(ListItemText)(({ theme }) => ({
  '&.MuiListItemText-secondary': {
    marignTop: '4px',
    color: theme.palette.text.secondary
  }
}));

// const styles = (theme) => ({
  // root: {
  //   width: '350px',
  //   maxWidth: '100%',
  // },
  // header: {
  //   backgroundColor: theme.palette.background.default,
  //   backgroundPositionX: 'right',
  //   backgroundPositionY: 'center',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize: 'contain',
  //   padding: theme.spacing(2, 1)
  // },
  // subtitle: {
  //   color: theme.palette.text.secondary
  // },
  // content: {},
  // listItem: {
  //   cursor: 'pointer',
  //   '&:hover': {
  //     backgroundColor: theme.palette.background.default
  //   }
  // },
  // listItemIcon: {
  //   backgroundColor: 'rgba(0, 0, 0, 0.03)',
  //   borderRadius: '50%',
  //   marginRight: theme.spacing(2),
  //   minWidth: 0,
  //   padding: '10px'
  // },
  // listItemTextSecondary: {
  //   marignTop: '4px',
  //   color: theme.palette.text.secondary
  // },
  // listItemActionIcon: {
  //   color: theme.palette.text.secondary,
  //   height: '16px',
  //   width: '16px'
  // },
  // empty: {
  //   textAlign: 'center',
  //   padding: theme.spacing(3)
  // }
// });

// export default styles