const handleFieldChange = (setState) => (event) => {
  const { checked, id, name, type, value } = event.target;
  const newValue = type === 'checkbox' ? checked : value;
  
  setState((prevState) => ({
    ...prevState,
    values: {
      ...prevState.values,
      [id ? id : name]: newValue,
    },
  }));
};

export default handleFieldChange;
 