import axios from 'axios';
import jwt_decode from 'jwt-decode';
import setAuthToken from '../utils/setAuthToken';

export const loginLogout = (payload) => {
  return {
    type: 'LOGIN_LOGOUT',
    payload
  }
}

export const attemptLogin = ({ remember = false, ...userData } = {}) => async (
  dispatch
) => {
  dispatch(setUserLoading(true));
  return axios
    .post('/api/auth/login', userData)
    .then((res) => {
      if (!res.data.success) {       
        throw new Error();
      }
      return dispatch(handleLogin(res, remember));
    })
    .catch((err) => {
      dispatch(setUserLoading(false));
      throw err.response.data.errors;
    });
};

export const checkSavedLogin = () => async (dispatch) => {
  const token = localStorage.getItem('session');

  if (!token) {
    dispatch(setUserLoading(false));
    return;
  }
  dispatch(handleLogin({ data: { token: token } }));
  
  if (window.navigator.onLine) {
    dispatch(verifyToken());
  }
};

export const handleLogin = (res, remember = false, newUsr = false) => async (dispatch) => {
  const { token } = res.data;

  if (remember) {
    // const orgStr = JSON.stringify(org);

    localStorage.setItem('session', token);
    // localStorage.setItem('org', orgStr);
  }
  
  setAuthToken(token);

  const decoded = jwt_decode(token);

  dispatch(setCurrentUser(decoded, newUsr));
};

export const logoutUser = () => async (dispatch) => {
  
  localStorage.removeItem('session');
  
  setAuthToken(false);
  
  dispatch(setCurrentUser({}));
};

// export const registerUser = (data) => async (dispatch) => {
//   return axios
//     .post('/api/auth/register', data)
//     .then((res) => {
//       if (!res.data.success) {
//         throw new Error();
//       }
//       return res; 
//     })
//     .catch((err) => {
//       throw err.response.data.errors;
//     });
// };

// export const resetPassword = (passwords) => async (dispatch) => {
//   return axios
//     .post('/api/auth/password-reset', passwords)
//     .then((res) => {
//       if (!res.data.success) {
//         throw new Error();
//       }
//       dispatch(logoutUser());
//     })
//     .catch((err) => {

//       throw err.response.data.errors
//     });
// };

export const resetPasswordRequest = (data) => async (dispatch) => {
  return axios
    .post('/api/auth/reset-password', data)
    .then((res) => {
      if (!res.data.success) {
        throw new Error();
      }

    })
    .catch((err) => {

      throw err.response.data.errors;
    });
};

export const setCurrentUser = (user, newUsr) => ({
  type: !!newUsr ? 'SET_NEW_USER' : 'SET_USER',
  isAuthenticated: Object.keys(user).length !== 0,
  // org,
  user,
});

export const setNewUser = (user) => ({
  type: 'SET_NEW_USER',
  isAuthenticated: Object.keys(user).length !== 0,
  user,
});

export const setUserLoading = (loading = true) => ({
  type: 'SET_LOADING',
  loading
});

export const updatePassword = (passwords) => async () => {
  return axios('/api/auth/update-password', {
    method: 'post',
    data: passwords
  })
    .then((res) => {
      // throw new Error();
    })
    .catch((err) => {
      console.log(err);
      // throw err.response.data.errors
    });
};

export const updateSettings = (payload) => ({
  type: 'UPDATE_SETTINGS',
  payload: payload
});

export const verifyToken = () => async (dispatch) => {
  return axios
    .get('/api/auth/verify-token')
    .then((res) => {
      if (!res.data.success) {

        throw new Error();
      }
      
      if (res.data.token) {
        
        dispatch(handleLogin(res, true));
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.status === 401) {
          
          dispatch(logoutUser());
        }
      }
    });
};
