const actions = {
  ORGANIZATION_CREATE: 'ORGANIZATION_CREATE',
  ORGANIZATION_EDIT: 'ORGANIZATION_EDIT',
  ORGANIZATION_VIEW: 'ORGANIZATION_VIEW',
  ADMIN_SETTINGS_EDIT: 'ADMIN_SETTINGS_EDIT',
  ADMIN_SETTINGS_VIEW: 'ADMIN_SETTINGS_VIEW',
  PROPOSAL_ARCHIVE: 'PROPOSAL_ARCHIVE',
  PROPOSAL_CREATE: 'PROPOSAL_CREATE',
  PROPOSAL_EDIT: 'PROPOSAL_EDIT',
  PROPOSAL_VIEW: 'PROPOSAL_VIEW',
  USER_CREATE: 'USER_CREATE',
  DASHBOARD_VIEW: 'DASHBOARD_VIEW',
  USER_VIEW: 'USER_VIEW',
  USER_EDIT: 'USER_EDIT',
  PULL_SHEET_VIEW: 'PULL_SHEET_VIEW',
  SEND_COPY_CLIENT: 'SEND_COPY_CLIENT',
  ADD_CLIENT: 'ADD_CLIENT',
  EDIT_RESPONSE: 'EDIT_RESPONSE',
  NOT_SUPER: 'NOT_SUPER',
  TECH_VIEW: 'TECH_VIEW',
  BILLING_VIEW: 'BILLING_VIEW',
  CREATE_TECH: 'CREATE_TECH',
  DELETE_PROPOSAL: 'DELETE_PROPOSAL',
  CREATE_ST_PROPOSAL: 'CREATE_ST_PROPOSAL'
};

export default actions;
