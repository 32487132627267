import { DateTime } from 'luxon';
// import connect from 'common/indexedDB';
// import axios from 'axios';

const initState = {
  proposalId: '',
  technician: '',
  data: {},
  products: [],
  priceBook: {},
  status: 'IN_PROGRESS',
  createdAt: '',
  updatedAt: '',
  organization: '',
  // callback1: false,
  // callback2: false,
  // checkedCB1: '',
  // checkedCB2: '',
};

const initProduct = {
  quantity: 1,
  location: '',
  completed: false,
  report: {},
};

const proposalReducer = (state = initState, action = {}) => {
  switch(action.type) {
    case 'ADD_PROPOSAL':
      return {
        ...action.payload,
      };
    case 'ADD_PRODUCT':
      return {
        ...state,
        products: [
          ...state.products,
          initProduct
        ],
        updatedAt: DateTime.now().toISO()
      };
    case 'UPDATE_PROPOSAL':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        },
        updatedAt: DateTime.now().toISO(), 
      };
    case 'STORE_PRICEBOOK':
      return {
        ...state,
        priceBook: {
          ...state.priceBook,
          ...action.payload
        }
      }
    case 'UPDATE_PRODUCT':
      const products = [...state.products];

      const newProduct = { 
        ...state.products[action.payload.index],
        [action.payload.id]: action.payload.value 
      };
      
      products.splice(action.payload.index, 1, newProduct);

      return {
        ...state,
        products: products,
        updatedAt: DateTime.now().toISO(),
      };

    case 'LARGE_PRODUCT_UPDATE':
      const prevProducts = [...state.products];

      const product = { 
        ...state.products[action.payload.index],
        ...action.payload.values
      };
      
      prevProducts.splice(action.payload.index, 1, product);

      return {
        ...state,
        products: prevProducts,
        updatedAt: DateTime.now().toISO(),
      };
    
    case 'UPDATE_PRODUCTS':
      return {
        ...state,
        products: action.payload,
        updatedAt: DateTime.now().toISO(),
      }
    // case 'UPDATE_SOLUTION':
    //   const allProducts = [...state.products];

    //   const updatedProduct = {
    //     ...state.products[action.payload.index],
    //     [action.payload.id]: {
    //       ...state.products[action.payload.index][action.payload.id],
    //       [action.payload.field]: action.payload.value,
    //     }
    //   };

    //   allProducts.splice(action.payload.index, 1, updatedProduct);

    //   return {
    //     ...state,
    //     products: allProducts,
    //     updatedAt: DateTime.now().toISO(),
    //   };
      // return state;
    case 'UPDATE_REPORT':
      const allProducts = [...state.products];
      const updatedProduct = {
        ...state.products[action.payload.index],
        report: {
          ...state.products[action.payload.index]?.report,
          [action.payload.id]: action.payload.value,
        },
      };

      allProducts.splice(action.payload.index, 1, updatedProduct);

      return {
        ...state,
        products: allProducts,
        updatedAt: DateTime.now().toISO(),
      };
    case 'REMOVE_REPORT_ITEM':
      const editableProducts = [...state.products];

      const reportItemObj = {
        ...state.products[action.payload.index],
        report: {
          ...state.products[action.payload.index]?.report
        }
      }

      delete reportItemObj.report[action.payload.id];

      editableProducts.splice(action.payload.index, 1, reportItemObj);

      return {
        ...state,
        products: editableProducts,
        updatedAt: DateTime.now().toISO()
      };
    case 'RESET_PROPOSAL':
      return {
        ...initState
      };
    case 'SET_PHOTO_URL':
      const productsCopy = [...state.products];
      
      const updatedPhotoURL = {
        ...state.products[action.index],
        [action.fieldId]: state.products[
          action.index
        ][
          action.fieldId
        ].map((url) => {
          if (url === action.dataUrl) {
            return action.url;
          }
          return url;
        }),
      };

      productsCopy.splice(action.index, 1, updatedPhotoURL);

      return {
        ...state,
        products: productsCopy,
        updatedAt: DateTime.now().toISO(),
      };
    default:
      return state;
  }
};

export default proposalReducer;
