import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import AppRoutes from './AppRoutes';
import store from './configureStore';
import theme from './theme';
import { checkSavedLogin } from './actions/auth';

import './styles/index.scss';

store.dispatch(checkSavedLogin());

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router>
        <AppRoutes />
      </Router>
    </ThemeProvider>
  </Provider>
);

export default App;
