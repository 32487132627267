import { Hidden } from '@mui/material';
// import { useParams } from 'react-router-dom';
import Can from 'components/helpers/Can';
import AdapterLink from 'components/utils/AdapterLink';
import {
  Icon,
  StyledButton,
  StyledItem,
  StyledList
} from 'styles/layouts/AppLayout/SideBarNav.style';
import pages from './pages';


const SidebarNav = ({ onClose }) => (
  <StyledList>
    {pages.map((page) => (
      <Hidden lgUp={page.mobileOnly} key={page.title}>
        <Can action={page.action} version={page.version}>
          <StyledItem disableGutters>
            <StyledButton
              component={AdapterLink}
              to={page.href}
              onClick={onClose}
            >
              <Icon>{page.icon}</Icon>
              {page.title}
            </StyledButton>
          </StyledItem>
        </Can>
      </Hidden>
    ))}
  </StyledList>
);

export default SidebarNav;
