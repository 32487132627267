import decode from 'jwt-decode';

const tokenDecoder = (payload) => {
  let token = payload;

  const decoded = (toDecode) => {
    let decodedToken;

    if (toDecode) {
      decodedToken = decode(toDecode);
    }
    return decodedToken;
  };

  let startingState = {};

  const decodedToken = decoded(token);

  if (decodedToken) {
    startingState = {
      isAuthenticated: decodedToken.exp > Date.now() / 1000 ? true : false,
      isLoading: false,
      user: decodedToken.user,
      organization: decodedToken.organization,
    };
  } else {
    const local = localStorage.getItem('session');
    // if a token is in localStorage
    if (local) {
      let sessionData = decoded(localStorage.getItem('session'));

      startingState = {
        isAuthenticated: sessionData.exp > Date.now() / 1000 ? true : false,
        isLoading: false,
        user: sessionData.user,
        organization: sessionData.organization,
      };
    } else {
      startingState = {
        isAuthenticated: false,
        isLoading: false,
        user: {},
        organization: {}
      }
    }
  }
  return startingState;
}

const initialState = tokenDecoder();

const state = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'LOGIN_LOGOUT':
      return tokenDecoder(action.payload);
    case 'UPDATE_SETTINGS':
      return {
        ...tokenDecoder(action.payload)
      };

    // case 'LOGIN':
    //   return tokenDecoder();
    case 'SET_LOADING':
      return {
        ...state,
        isAuthenticated: false,
        isLoading: action.loading,
        user: {},
      };
    case 'SET_USER':
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        isLoading: false,
        user: action.user.user,
        organization: action.user.organization
      };
    // case 'SET_NEW_USER':
    //   return {
    //     ...state,
    //     isAuthenticated: action.isAuthenticated,
    //     isLoading: false,
    //     user: action.user,
    //     organization: action.org,
    //     newUser: true,
    //   };
    default:
      return state;
  }
};

export default state;
