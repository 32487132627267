import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Code,
  Info,
  PriorityHigh
} from '@mui/icons-material';
import {
  Divider,
  List,
  Typography,
  Button,
} from '@mui/material';
import {
  Header,
  Subtitle,
  Content,
  StyledListItem,
  StyledListIcon,
  Empty,
  CancelAction,
  ForwardAction,
  ContainerDiv,
  StyledListText,
} from 'styles/layouts/AppLayout/NotificationList.style';

const icons = {
  error: {
    icon: <PriorityHigh />,
    color: 'red',
  },
  info: {
    icon: <Info />,
    color: 'blue',
  },
  feature: {
    icon: <Code />,
    color: 'purple',
  },
};

const NotificationList = ({
  // className,
  notifications,
  notificationsTotal,
  onClickNotification,
  handleReloadApp,
}) =>  (
  <ContainerDiv>
    {notifications.length > 0 ? (
      <>
        <Header>
          <Typography variant="h6">Notifications</Typography>
          <Subtitle variant="body2">
            {notificationsTotal} total
          </Subtitle>
        </Header>
        <Content>
          <List component="div">
            {notifications.map((notification) => (
              <NavLink key={notification.id} to={notification.route}>
                <StyledListItem
                  component="div"
                  onClick={() => onClickNotification(notification.id)}
                >
                  <StyledListIcon
                    style={{ color: icons[notification.type].color }}
                  >
                    {icons[notification.type].icon}
                  </StyledListIcon>
                  <StyledListText
                    primary={notification.title}
                    secondary={notification.when.toRelative()}
                  />
                  {notification.title ===
                    'Updated app. click below to refresh and update.' && (
                    <StyledListText
                      primary={
                        <Fragment>
                          <Button
                            onClick={() => handleReloadApp()}
                            color="primary"
                          >
                            Reload App
                          </Button>
                        </Fragment>
                      }
                    ></StyledListText>
                  )}

                  {notification.route === '#' ? (
                    <CancelAction />
                  ) : (
                    <ForwardAction />
                  )}
                </StyledListItem>
                <Divider />
              </NavLink>
            ))}
          </List>
        </Content>
      </>
    ) : (
      <Empty>
        <Typography variant="h4">All caught up!</Typography>
      </Empty>
    )}
  </ContainerDiv>
);

if (process.env.NODE_ENV !== 'production') {
  NotificationList.propTypes = {
    // className: PropTypes.string,
    notifications: PropTypes.array.isRequired,
    notificationsTotal: PropTypes.number.isRequired,
    onClickNotification: PropTypes.func.isRequired,
  };
}

export default NotificationList;
