import { styled } from '@mui/material';
import Footer from 'layouts/AppLayout/Footer';

export const RootDiv = styled('div')(({ theme, shiftContent }) => {
  const shift = {
    paddingLeft: 240,
  };

  return {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
    ...(shiftContent ? shift : {}),
  }
});

export const Main = styled('main')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 64px);
`;

export const StyledFooter = styled(Footer)(({ theme }) => ({
  padding: theme.spacing(6),
}));
