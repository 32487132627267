import { useState } from 'react';

import handleFieldChange from './handleFieldChange';
import handleFormError from './handleFormError';
import handleFormSuccess from './handleFormSuccess';
import handleLoadPreviousForm from './handleLoadPreviousForm';

const useFormState = (
  initialValues = {},
  unknownErrorProp = '',
  initialInfo = ''
) => {
  const intitialState = {
    info: initialInfo,
    values: initialValues,
    errors: {},
  };

  const [state, setState] = useState(intitialState);

  return {
    setState,
    state,
    handleChange: handleFieldChange(setState),
    handleError: handleFormError(setState, unknownErrorProp),
    handleSuccess: handleFormSuccess(setState, intitialState),
    handleLoadPreviousForm: handleLoadPreviousForm(setState),
  };
};

export default useFormState;
