import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid';

export const appError = ({
  id = uuidv4(),
  title = 'Unknown error',
  route = '#',
  when = DateTime.now()
} = {}) => ({
  type: 'APP_ERROR',
  notification: {
    id,
    title,
    route,
    type: 'error',
    when,
    read: false
  }
});

export const appInfo = ({
  id = uuidv4(),
  title = '',
  route = '#',
  when = DateTime.now()
} = {}) => {
  if (title) {
    return {
      type: 'APP_INFO',
      notification: {
        id,
        title,
        route,
        type: 'info',
        when,
        read: false
      }
    };
  }
};

export const dismissNotification = ({ id = '' } = {}) => {
  if (id) {
    return {
      type: 'DISMISS_NOTIFICATION',
      id
    };
  }
};

export const readNotification = ({ id = '' } = {}) => {
  if (id) {
    return {
      type: 'READ_NOTIFICATION',
      id
    };
  }
};
