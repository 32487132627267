import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
// import axios from 'axios';
// import clsx from 'clsx';
import PropTypes from 'prop-types';
// import Can from 'components/helpers/Can';
import {
  // AppBar,
  Badge,
  Hidden,
  IconButton,
  // Menu,
  // MenuItem,
  Popover,
  Toolbar,
  // Typography,
} from '@mui/material';

import {
  LogoImg,
  LogoText,
  RightButton,
  Spacer,
  StyledAppBar,
} from '../../../styles/layouts/AppLayout/TopBar.style';

import { Menu, NotificationsOutlined } from '@mui/icons-material';

import NotificationList from './NotificationList';

import { dismissNotification, readNotification } from 'actions/notifications';
// import {
//   ORGANIZATION_CREATE,
//   ADMIN_SETTINGS_VIEW,
//   ORGANIZATION_VIEW,
// } from 'common/rbac/actions';

const MAX_NOTIFICATIONS = 3;

const Topbar = ({
  dismissNotification,
  onSidebarOpen,
  notifications,
  readNotification,
}) => {
  const org = useSelector((state) => state.auth.organization);

  const [notificationsEl, setNotificationElement] = useState(null);
  const showNotifications = Boolean(notificationsEl);

  const unreadNotifications = Boolean(
    notifications.find((notification) => notification.read === false)
  );

  const markShownAsRead = () =>
    notifications
      .slice(0, MAX_NOTIFICATIONS)
      .map((notification) => readNotification(notification));

  const handleShowNotifications = (event) =>
    setNotificationElement(event.currentTarget);

  const handleClickNotification = (id) => dismissNotification({ id });
  const handleReloadApp = () => {
    window.location.reload(true);
  };

  const handleCloseNotifications = () => {
    setNotificationElement(null);
    markShownAsRead();
  };

  // const [menuEl, setMenuElement] = useState(null);
  // const showMenu = Boolean(menuEl);

  // const handleShowMenu = (event) => setMenuElement(event.currentTarget);
  // const handleCloseMenu = () => setMenuElement(null);

  return (
    <>
      <StyledAppBar>
        <Toolbar>
          <NavLink to="/">
            <LogoImg src='/static/logo_512.png' alt="Bear Hands Pro Logo" />
          </NavLink>
          <NavLink to="/">
            <Hidden xsDown>
              <LogoText
                noWrap
                // sx={{ variant: 'h2' }}
                variant="h2"
              >
                {org?.organizationName}
              </LogoText>
            </Hidden>            
          </NavLink>
          <Spacer />
          <IconButton
            color="inherit"
            onClick={handleShowNotifications}
          >
            <Badge
              badgeContent={unreadNotifications ? 1 : 0}
              color="secondary"
              variant="dot"
            >
              <NotificationsOutlined />
            </Badge>
          </IconButton>
          {/* <Hidden mdDown>
            <IconButton
              className={classes.rightButton}
              color="inherit"
              onClick={handleShowMenu}
            >
              <Settings />
            </IconButton>
          </Hidden> */}
          <Hidden lgUp>
            <RightButton
              color="inherit"
              onClick={onSidebarOpen}
            >
              <Menu />
            </RightButton>
          </Hidden>
        </Toolbar>
      </StyledAppBar>
      {/* <Menu
        id="accountMenu"
        anchorEl={menuEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={showMenu}
        onClose={handleCloseMenu}
      >
        <NavLink to="/settings">
          <MenuItem onClick={handleCloseMenu}>User Settings</MenuItem>
        </NavLink>
        <Can action={ORGANIZATION_VIEW}>
          <NavLink to="/createUser">
            <MenuItem onClick={handleCloseMenu}>Create User</MenuItem>
          </NavLink>
        </Can>
        <Can action={ORGANIZATION_CREATE}>
          <NavLink to="/createorg">
            <MenuItem onClick={handleCloseMenu}>Create Organization</MenuItem>
          </NavLink>
        </Can>
        <Can action={ADMIN_SETTINGS_VIEW}>
          <NavLink to="/admin">
            <MenuItem onClick={handleCloseMenu}>Admin Control Panel</MenuItem>
          </NavLink>
        </Can>
        <NavLink to="/logout">
          <MenuItem onClick={handleCloseMenu}>Sign Out</MenuItem>
        </NavLink>
      </Menu> */}
      <Popover
        anchorEl={notificationsEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={handleCloseNotifications}
        open={showNotifications}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <NotificationList
          notifications={notifications.slice(0, MAX_NOTIFICATIONS)}
          notificationsTotal={notifications.length}
          onClickNotification={handleClickNotification}
          handleReloadApp={handleReloadApp}
        />
      </Popover>
    </>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Topbar.propTypes = {
    dismissNotification: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
    onSidebarOpen: PropTypes.func.isRequired,
    readNotification: PropTypes.func.isRequired,
  };
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.app,
});

export default connect(mapStateToProps, {
  dismissNotification,
  readNotification,
})(Topbar);
