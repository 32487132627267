import permissions from './permissions';

const check = (role, action, context) => {
  const rolePermissions = permissions[role];

  if (!rolePermissions) {
    return false;
  }

  const actionPermission = rolePermissions[action];

  if (typeof actionPermission === 'boolean') {
    return actionPermission;
  }

  if (typeof actionPermission === 'function') {
    return actionPermission(context);
  }
  return false;
};

export default check;
