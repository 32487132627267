const loadProposalsReducer = (state = [], action = {}) => {
  switch(action.type) {
    case 'LOAD_PROPOSALS':
      return [...action.payload];
    default:
      return state;
  }
}

export default loadProposalsReducer;
