import actions from './actions';

const {
  ORGANIZATION_CREATE,
  ORGANIZATION_EDIT,
  ORGANIZATION_VIEW,
  ADMIN_SETTINGS_EDIT,
  ADMIN_SETTINGS_VIEW,
  PROPOSAL_ARCHIVE,
  PROPOSAL_CREATE,
  PROPOSAL_EDIT,
  PROPOSAL_VIEW,
  USER_CREATE,
  DASHBOARD_VIEW,
  USER_EDIT,
  USER_VIEW,
  PULL_SHEET_VIEW,
  SEND_COPY_CLIENT,
  ADD_CLIENT,
  EDIT_RESPONSE,
  NOT_SUPER,
  TECH_VIEW,
  BILLING_VIEW,
  CREATE_TECH,
  DELETE_PROPOSAL,
  CREATE_ST_PROPOSAL
} = actions;

const permissions = {
  'SUPER_ADMIN': {
    [ORGANIZATION_CREATE]: true,
    [ORGANIZATION_EDIT]: true,
    [ORGANIZATION_VIEW]: true,
    [ADMIN_SETTINGS_EDIT]: true,
    [ADMIN_SETTINGS_VIEW]: true,
    [PROPOSAL_ARCHIVE]: true,
    [PROPOSAL_CREATE]: true,
    [PROPOSAL_EDIT]: true,
    [PROPOSAL_VIEW]: true,
    [USER_CREATE]: true,
    [DASHBOARD_VIEW]: true,
    [USER_EDIT]: true,
    [USER_VIEW]: true,
    [PULL_SHEET_VIEW]: true,
    [SEND_COPY_CLIENT]: true,
    [ADD_CLIENT]: true,
    [EDIT_RESPONSE]: true,
    [NOT_SUPER]: false,
    [TECH_VIEW]: true,
    [BILLING_VIEW]: true,
    [CREATE_TECH]: true,
    [DELETE_PROPOSAL]: true,
    [CREATE_ST_PROPOSAL]: true,
  },
  'ADMIN': {
    [ORGANIZATION_CREATE]: false,
    [ORGANIZATION_EDIT]: true,
    [ORGANIZATION_VIEW]: false,
    [ADMIN_SETTINGS_EDIT]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [ADMIN_SETTINGS_VIEW]: true,
    [PROPOSAL_ARCHIVE]: true,
    [PROPOSAL_CREATE]: true,
    [PROPOSAL_EDIT]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [PROPOSAL_VIEW]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [USER_CREATE]: true,
    [DASHBOARD_VIEW]: true,
    [USER_EDIT]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [USER_VIEW]: true,
    [BILLING_VIEW]: true,
    [PULL_SHEET_VIEW]: true,
    [SEND_COPY_CLIENT]: true,
    [ADD_CLIENT]: true,
    [EDIT_RESPONSE]: true,
    [NOT_SUPER]: true,
    [TECH_VIEW]: true,
    [CREATE_TECH]: true,
    [DELETE_PROPOSAL]: true,
    [CREATE_ST_PROPOSAL]: true,
  },
  'OFFICE': {
    [ORGANIZATION_CREATE]: false,
    [ORGANIZATION_EDIT]: false,
    [ORGANIZATION_VIEW]: false,
    [ADMIN_SETTINGS_EDIT]: false,
    [ADMIN_SETTINGS_VIEW]: false,
    [PROPOSAL_ARCHIVE]: true,
    [PROPOSAL_CREATE]: false,
    [PROPOSAL_EDIT]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [PROPOSAL_VIEW]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [USER_CREATE]: true,
    [DASHBOARD_VIEW]: true,
    [USER_EDIT]: true,
    [USER_VIEW]: true,
    [BILLING_VIEW]: false,
    [PULL_SHEET_VIEW]: true,
    [SEND_COPY_CLIENT]: true,
    [ADD_CLIENT]: true,
    [EDIT_RESPONSE]: true,
    [NOT_SUPER]: true,
    [TECH_VIEW]: false,
    [CREATE_TECH]: false,
  },
  'TECHNICIAN': {
    [ORGANIZATION_CREATE]: false,
    [ORGANIZATION_EDIT]: false,
    [ORGANIZATION_VIEW]: false,
    [ADMIN_SETTINGS_EDIT]: false,
    [ADMIN_SETTINGS_VIEW]: false,
    [PROPOSAL_ARCHIVE]: false,
    [PROPOSAL_CREATE]: true,
    [PROPOSAL_EDIT]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [PROPOSAL_VIEW]: ({ otherOrganizationId, user }) => {
      if (!otherOrganizationId || !user.organizationId) return false;
      return otherOrganizationId === user.organizationId;
    },
    [USER_CREATE]: false,
    [DASHBOARD_VIEW]: true,
    [USER_EDIT]: false,
    [USER_VIEW]: false,
    [PULL_SHEET_VIEW]: true,
    [SEND_COPY_CLIENT]: false,
    [ADD_CLIENT]: false,
    [EDIT_RESPONSE]: false,
    [NOT_SUPER]: true,
    [TECH_VIEW]: true,
    [BILLING_VIEW]: false,
    [CREATE_TECH]: false,
    [DELETE_PROPOSAL]: true,
    [CREATE_ST_PROPOSAL]: true,
  },
};

export default permissions;
