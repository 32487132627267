import {
  styled,
  Typography,
  TextField,
  Checkbox,
  Button,
  CircularProgress,
  Grid,
  Link,
} from '@mui/material';

export const RootForm = styled('form')(({ theme }) => ({
  paddingLeft: '100px',
  paddingRight: '100px',
  paddingBottom: '75px',
  flexBasis: '700px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  marginBottom: `${theme.spacing(3)} !important`,
  [theme.breakpoints.down('sm')]: {
    marginTop: '100px',
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& + & ': {
    marginTop: `${theme.spacing(2)} !important`,
  },
}));

export const FieldError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(1),
}));

export const RememberContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const RememberCheckbox = styled(Checkbox)`
  margin-left: '-11px'
`;

export const RememberText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: 'inline',
}));

export const SignInWrapper = styled('div')`
  position: relative;
`;

export const SignInButton = styled(Button)(({ theme }) => ({
  marginTop: `${theme.spacing(2)} !important`,
  width: '100%',
}));

export const ButtonProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -4;
  margin-left: -12;
`;

export const SubText = styled(Grid)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginTop: `${theme.spacing(2)} !important`,
}));

export const SubTextLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  '&:hover': {
    color: theme.palette.primary.main
  }
}));

const styles = (theme) => ({
  // root: {
  //   paddingLeft: '100px',
  //   paddingRight: '100px',
  //   paddingBottom: '75px',
  //   flexBasis: '700px',
  //   [theme.breakpoints.down('sm')]: {
  //     paddingLeft: theme.spacing(2),
  //     paddingRight: theme.spacing(2)
  //   }
  // },
  // title: {
  //   marginBottom: `${theme.spacing(3)} !important`,
  //   [theme.breakpoints.down('sm')]: {
  //     marginTop: '100px',
  //   }
  // },
  // fields: {
  //   marginTop: `${theme.spacing(5)} !important`
  // },
  // textField: {
  //   width: '100%',
  //   '& + & ': {
  //     marginTop: `${theme.spacing(2)} !important`
  //   }
  // },
  // remember: {
  //   display: 'flex',
  //   alignItems: 'center'
  // },
  // rememberCheckbox: {
  //   marginLeft: '-11px'
  // },
  // rememberText: {
  //   color: theme.palette.text.secondary,
  //   display: 'inline'
  // },
  // signInWrapper: {
  //   position: 'relative'
  // },
  // signInButton: {
  //   marginTop: `${theme.spacing(2)} !important`,
  //   width: '100%'
  // },
  // buttonProgress: {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   marginTop: -4,
  //   marginLeft: -12
  // },
  // subText: {
  //   color: theme.palette.text.secondary,
  //   marginTop: `${theme.spacing(2)} !important`
  // },
  // subTextUrl: {
  //   color: theme.palette.primary.main,
  //   fontWeight: 'bold',
  //   '&:hover': {
  //     color: theme.palette.primary.main
  //   }
  // },
  // fieldError: {
  //   color: theme.palette.error.main,
  //   marginBottom: theme.spacing(2),
  //   marginTop: theme.spacing(1)
  // },
  submitError: {
    color: theme.palette.error.main,
    alignText: 'center',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  authFooter: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

export default styles;
