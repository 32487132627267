import { useState } from 'react';
// import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  AppBar,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Toolbar,
  Container,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';

import styles from './styles';
// import { dismissNotification, readNotification } from 'actions/notifications';

const useStyles = makeStyles(styles);

const Topbar = () => {
  const classes = useStyles();

  const [anchorEl, setActiveEl] = useState(null);
  const showMenu = !!anchorEl;

  const handleMenuOpen = (event) => {
    setActiveEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setActiveEl(null);
  };

  return (
    <>
      <AppBar position='sticky' className={classes.root}>
        <Container>
          <Toolbar disableGutters>
            <img src="/static/logo_512.png" className={classes.logo} alt="bhp logo" />
            <NavLink to="/">
              <Typography variant="h2" className={classes.logoText}>
                Grizzly Gauge
              </Typography>
              <Typography align='center' variant="caption" className={classes.logoSubText}>
                Service Proposal Software
              </Typography>
            </NavLink>
            <div className={classes.flexGrow} />
            <Hidden smDown>
              <NavLink to="/login">
                <Button className={classes.navLink} color="inherit">
                  Login
                </Button>
              </NavLink>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                className={classes.rightButton}
                color="inherit"
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Toolbar>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={showMenu}
            onClose={handleMenuClose}
          >
            <NavLink to="/login">
              <MenuItem>Login</MenuItem>
            </NavLink>
          </Menu>
        </Container>
      </AppBar>
    </>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Topbar.propTypes = {
    className: PropTypes.string,
  };
}

// const mapStateToProps = (state) => ({
//   notifications: state.notifications.app,
// });

export default Topbar;
