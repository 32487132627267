import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

const publicComponent = (BaseComponent) => {

  return connect(mapStateToProps)(({ isAuthenticated, ...props }) => {
    return isAuthenticated ?  (
      <Navigate to="/dashboard" />
    ) : (
      <BaseComponent {...props} />
    );
  });
};

export default publicComponent;
