import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { logoutUser } from 'actions/auth';

const Logout = ({ logoutUser }) => {
  logoutUser();
  return <Navigate push to="/login" />;
};

export default connect(
  null,
  { logoutUser }
)(Logout);
