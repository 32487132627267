const initialState = {
  app: []
};

const state = (state = initialState, action) => {
  switch (action.type) {
    case 'APP_ERROR':
    case 'APP_INFO':
      return {
        ...state,
        app: [action.notification, ...state.app]
      };
    case 'DISMISS_NOTIFICATION':
      return {
        ...state,
        app: state.app.filter((notification) => notification.id !== action.id)
      };
    case 'READ_NOTIFICATION':
      return {
        ...state,
        app: state.app.map((notification) => {
          if (notification.id === action.id) {
            return {
              ...notification,
              read: true
            };
          }

          return notification;
        })
      };
    default:
      return state;
  }
};

export default state;
