import { Suspense, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import Can from 'components/helpers/Can';
import LoginDialog from '../auth/LoginDialog';
import { appError, appInfo } from 'actions/notifications';
import { register } from 'common/serviceWorker';

const handleAuthenticated = (error, info) => {
  const onFailure = (title = '') => {
    title = title || 'Unknown error. App will NOT work offline.';
    error({ title });
  };

  const onSuccess = () => info({ title: 'App is available for offline mode' });

  const onUpdate = () =>
    info({ title: 'Updated app. click below to refresh and update.' });

  register({ onFailure, onSuccess, onUpdate });
};

// Higher Order Component which uses code splitting techniques to separate "Restricted" Components
//  Example of call:
//  export default protectedComponent(() => import('./CreateUser'), { //this line is component
//   action: action.USER_CREATE, //this is user permision
// });

const protectedComponent = (restrictedComponent, canProps) => {
  const BaseComponent = lazy(restrictedComponent);

  return (props) => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);

    useEffect(() => {
      if (isAuthenticated) {
        const error = (props) => dispatch(appError(props));
        const info = (props) => dispatch(appInfo(props));
        handleAuthenticated(error, info);
      }
    }, [dispatch, isAuthenticated]);
    
    if (!isAuthenticated) {
      return <LoginDialog />;
    }

    const renderComponent = (
      <Suspense fallback={<LinearProgress />}>
        <BaseComponent {...props} />
      </Suspense>
    );
    
    if (!canProps) {
      return renderComponent;
    }

    return <Can {...canProps}>{renderComponent}</Can>;
  };
};

export default protectedComponent;
