import React from 'react';
import PropTypes from 'prop-types';
import TopBar from '../FrontEnd/Topbar';
import {
  ContentBody,
  ContentContainer,
  ContentGrid,
  HeroWrapper,
  LayoutContainer,
  PageContainer,
} from '../../styles/layouts/AuthLayout/AuthLayout.style';

const AuthLayout = ({ component: Component }) => (
  <LayoutContainer>
    <TopBar />
    <PageContainer container>
      <HeroWrapper item lg={5}>
      </HeroWrapper>
      <ContentGrid item lg={7} xs={12}>
        <ContentContainer>
          <ContentBody>
            <Component />
          </ContentBody>
        </ContentContainer>
      </ContentGrid>
    </PageContainer>
  </LayoutContainer>
);

if (process.env.NODE_ENV !== 'production') {
  AuthLayout.propTypes = {
    component: PropTypes.object,
  };
}

export default AuthLayout;
