import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import {
  NameText,
  RootDiv,
} from 'styles/layouts/AppLayout/Profile.style';

let roles = {
  ADMIN: 'Admin',
  SUPER_ADMIN: 'Super Admin',
  TECHNICIAN: 'Technician',
  OFFICE: 'Office'
};

const Profile = () => {
  const user = useSelector((state) => state.auth.user);

  return (
    <RootDiv>
      <NameText noWrap  variant="h4">
        {user.name}
      </NameText>
      {user.role && (
        <Typography variant="body2">{roles[user.role]}</Typography>
      )}
    </RootDiv>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Profile.propTypes = {
    className: PropTypes.string,
  };
}

export default Profile;
