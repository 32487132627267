import {
  colors,
  styled,
  List,
  ListItem,
  Button,
} from '@mui/material';

export const StyledList = styled(List)``;

export const StyledItem = styled(ListItem)`
  display: flex;
`;

export const StyledButton = styled(Button)(({ theme }) => ({
  color: colors.blueGrey[800],
  padding: '10px 8px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  fontWeight: theme.typography.fontWeightMedium,
  '&.active': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

export const Icon = styled('div')(({ theme }) => ({
  color: theme.palette.icon,
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1),
}));
