import {
  styled,
  Typography,
} from '@mui/material';

export const RootDiv = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: fit-content;
`;

export const NameText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  textAlign: 'center',
  width: '215px',
}));
