import {
  styled,
  Grid,
} from '@mui/material';

export const LayoutContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: '100vh'
}));

export const PageContainer = styled(Grid)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.common.neutral,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'url(/static/background.png)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  [theme.breakpoints.down('lg')]: {
    backgroundImage: 'none',
  }
}));

export const HeroWrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

const contentStyling = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column !important',
};

export const ContentGrid = styled(Grid)(() => (contentStyling));

export const ContentContainer = styled('div')(() => (contentStyling));

export const ContentBody = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));

// hero: {
//   backgroundColor: theme.palette.common.neutral,
//   height: '100%',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   backgroundImage: 'url(/static/photo-1508767899053-1d9cced0ffc4.png)',
//   backgroundSize: 'cover',
//   backgroundRepeat: 'no-repeat',
//   backgroundPosition: 'center'
// }
