import { configureStore } from '@reduxjs/toolkit';
import auth from './reducers/authenticationReducer';
import proposalReducer from 'reducers/proposalReducer';
import loadProposalsReducer from 'reducers/loadProposalsReducer';
import notifications from 'reducers/notificationsReducer';
import * as Sentry from '@sentry/react'; //Sentry Redux Monitoring

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = configureStore({
  reducer: {
    auth,
    proposal: proposalReducer,
    proposalList: loadProposalsReducer,
    notifications,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: true,
    serializableCheck: false,
    immutableCheck: false,
    devTools: true,
  }),
  enhancers: (defaultEnhancers) => defaultEnhancers.concat(sentryReduxEnhancer)
});

export default store;
