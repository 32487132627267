import {
  styled,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Link,
} from '@mui/material';

export const RootForm = styled('form')(({ theme }) => ({
  paddingLeft: '100px',
  paddingRight: '100px',
  paddingBottom: '75px',
  flexBasis: '700px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

export const FieldsDiv = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& + & ': {
    marginTop: theme.spacing(2),
  },
}));

export const FieldError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(1),
}));

export const SubmitInfo = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.main,
  alignText: 'center',
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

export const SubmitWrapper = styled('div')`
  position: relative;
`;

export const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
}));

export const ButtonProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -4;
  margin-left: -12;
`;

export const SubText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

export const SubTextLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  '&:hover': {
    color: theme.palette.primary.main
  }
}));
