import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import publicComponent from 'components/helpers/publicComponent';
import {
  FieldsDiv,
  RootForm,
  TitleText,
  StyledTextField,
  FieldError,
  SubmitInfo,
  SubmitWrapper,
  SubmitButton,
  ButtonProgress,
  SubText,
  SubTextLink,
} from '../../../styles/EmailPasswordReset.style';
import useFormState from 'utils/forms/useFormState';
import { resetPasswordRequest } from 'actions/auth';

const PasswordResetForm = ({ isLoading, resetPasswordRequest }) => {
  const [hasSubmitted, setSubmitted] = React.useState(false);

  const {
    state: { values, errors },
    handleChange,
    handleError,
    handleSuccess
  } = useFormState(
    {
      email: ''
    },
    'email'
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPasswordRequest(values)
      .then(() => {
        setSubmitted(true);
        
        handleSuccess();
      })
      .catch(handleError);
  };

  return (
    <RootForm noValidate onSubmit={handleSubmit}>
      <TitleText variant="h2">
        Reset Password
      </TitleText>
      <FieldsDiv>
        <StyledTextField
          id="email"
          label="Email address"
          autoComplete="email"
          autoFocus
          required
          type="email"
          variant="outlined"
          onChange={handleChange}
          value={values.email}
        />
        <FieldError variant="body2">
          {errors.email}
        </FieldError>
      </FieldsDiv>
      <SubmitInfo variant="body2">
        {hasSubmitted &&
          'An email will be sent shortly if an account exists with that email'}
      </SubmitInfo>
      <SubmitWrapper>
        <SubmitButton
          type="submit"
          disabled={isLoading || hasSubmitted}
          onClick={handleSubmit}
          color="primary"
          size="large"
          variant="contained"
        >
          Reset Password
        </SubmitButton>
        {isLoading && (
          <ButtonProgress size={24} />
        )}
      </SubmitWrapper>
      <SubText variant="body1">
        Don't have an account?{' '}
        <SubTextLink to="/signup" component={NavLink}
        >
          Sign Up
        </SubTextLink>
      </SubText>
      <SubText variant="body1">
        Have an account?{' '}
        <SubTextLink to="/login" component={NavLink}>
          Sign In
        </SubTextLink>
      </SubText>
    </RootForm>
  );
};

if (process.env.NODE_ENV !== 'production') {
  PasswordResetForm.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    resetPasswordRequest: PropTypes.func.isRequired
  };
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading
});

export default compose(
  connect(mapStateToProps, { resetPasswordRequest }),
  publicComponent
)(PasswordResetForm);
