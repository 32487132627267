import React from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import States from '../../../utils/usStates.json';
import {
  MenuItem,
  TextField,
} from '@mui/material';

import {
  RootForm,
  TitleText,
  FieldsContainer,
  StyledTextField,
  FieldError,
  LocationContainer,
  CityTextField,
  ErrorsContainer,
  SubmitWrapper,
  SubmitButton,
  ButtonProgress,
  SignInText,
  SignInLink,
} from '../../../styles/Register.style';

import publicComponent from 'components/helpers/publicComponent';
import useFormState from 'utils/forms/useFormState';
// import { registerUser } from 'actions/auth';
import axios from 'axios';
import { loginLogout } from 'actions/auth';

const Register = ({ isLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    state: { values, errors },
    // handleError,
    handleChange,
  } = useFormState(
    {
      organizationName: '',
      name: '',
      email: '',
      phoneNumber: '',
      streetAddress: '',
      city: '',
      state: '',
      zip: '',
      password: '',
      repeatPassword: '',
      // policy: false,
    },
    'name'
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    // registerUser(values).catch(handleError);
    axios.post('/api/auth/create-organization', values)
      .then((res) => {
        const token = res.headers.authorization.split(' ')[1];
        // on successful response post data to the store
        // post token and data to the browser
        // redirect to the dashboard
        sessionStorage.setItem('session', token);
        // dispatch new data to the store
        dispatch(loginLogout(token));
        // display appropriately
        navigate('/dashboard');
      })
      .catch((err) => {
        // use catch block to display errors depending on whether or not the registration was valid
        console.log(err)
      });
  };

  return (
    <RootForm noValidate onSubmit={handleSubmit}>
      <TitleText variant="h2">
        Create new account
      </TitleText>
      <FieldsContainer>
        <StyledTextField
          id="organizationName"
          label="Organization Name"
          autoComplete="organizationName"
          autoFocus
          required
          type="text"
          variant="outlined"
          onChange={handleChange}
          value={values.organizationName}
        />
        <FieldError variant="body2">
          {errors.organizationName}
        </FieldError>
        <StyledTextField
          id="name"
          label="Name"
          autoComplete="name"
          autoFocus
          required
          type="text"
          variant="outlined"
          onChange={handleChange}
          value={values.name}
        />
        <FieldError variant="body2">
          {errors.name}
        </FieldError>
        <StyledTextField
          id="email"
          label="Email Address"
          autoComplete="email"
          required
          type="text"
          variant="outlined"
          onChange={handleChange}
          value={values.email}
        />
        <FieldError variant="body2">
          {errors.phoneNumber}
        </FieldError>
        <StyledTextField
          id="phoneNumber"
          label="Phone Number"
          autoComplete="phoneNumber"
          required
          type="text"
          variant="outlined"
          onChange={handleChange}
          value={values.phoneNumber}
        />
        <FieldError variant="body2">
          {errors.phoneNumber}
        </FieldError>
        <StyledTextField
          id="streetAddress"
          label="Street Address"
          autoComplete="streetAddress"
          autoFocus
          required
          type="text"
          variant="outlined"
          onChange={handleChange}
          value={values.streetAddress}
        />
        <FieldError variant="body2">
          {errors.streetAddress}
        </FieldError>
        <LocationContainer>
          <CityTextField
            id="city"
            label="City"
            autoComplete="city"
            autoFocus
            required
            type="text"
            variant="outlined"
            onChange={handleChange}
            value={values.city}
          />
          <TextField
            id="states"
            label="State"
            name="state"
            // autoFocus
            required
            select
            variant="outlined"
            onChange={handleChange}
            value={values.state}
          >
            {States.map((state) => (
              <MenuItem id='state' value={state.value}>
                {state.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            id="zip"
            label="Zip"
            autoComplete="zip"
            autoFocus
            required
            type="text"
            variant="outlined"
            onChange={handleChange}
            value={values.zip}
          />
        </LocationContainer>

        <ErrorsContainer>
          <FieldError variant="body2">
            {errors.city}
          </FieldError>
          <FieldError variant="body2">
            {errors.state}
          </FieldError>
          <FieldError variant="body2">
            {errors.zip}
          </FieldError>   
        </ErrorsContainer>

        <StyledTextField
          id="password"
          label="Password"
          autoComplete="off"
          required
          type="password"
          variant="outlined"
          onChange={handleChange}
          value={values.password}
        />
        <FieldError variant="body2">
          {errors.password}
        </FieldError>
        <StyledTextField
          id="repeatPassword"
          label="Repeat Password"
          autoComplete="off"
          required
          type="password"
          variant="outlined"
          onChange={handleChange}
          value={values.repeatPassword}
        />
        <FieldError variant="body2">
          {errors.repeatPassword}
        </FieldError>
        {/* <div className={classes.policy}>
          <Checkbox
            checked={values.policy}
            className={classes.policyCheckbox}
            color="primary"
            id="policy"
            onChange={handleChange}
          />
          <Typography className={classes.policyText} variant="body1">
            I have read and agree to the{' '}
            <Link className={classes.policyUrl} to="#">
              Terms and Conditions
            </Link>
            .
          </Typography>
        </div> */}
        {/* <Typography className={classes.fieldError} variant="body2">
          {errors.policy}
        </Typography> */}
      </FieldsContainer>
      <SubmitWrapper>
        <SubmitButton
          type="submit"
          disabled={isLoading}
          onClick={handleSubmit}
          color="primary"
          size="large"
          variant="contained"
        >
          Sign Up
        </SubmitButton>
        {isLoading && (
          <ButtonProgress size={24} />
        )}
      </SubmitWrapper>
      <SignInText variant="body1">
        Have an account?{' '}
        <SignInLink to="/login" component={NavLink}>
          Sign In
        </SignInLink>
      </SignInText>
    </RootForm>
  );
};

// if (process.env.NODE_ENV !== 'production') {
//   Register.propTypes = {
//     isLoading: PropTypes.bool.isRequired,
//     registerUser: PropTypes.func.isRequired,
//   };
// }

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
});

export default compose(
  connect(mapStateToProps),
  publicComponent
)(Register);
