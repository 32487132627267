import {
  styled,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Link,
} from '@mui/material';

export const RootForm = styled('form')(({ theme }) => ({
  paddingLeft: '100px',
  paddingRight: '100px',
  paddingBottom: '75px',
  flexBasis: '700px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: `${theme.spacing(2)} !important`,
    paddingRight: `${theme.spacing(2)} !important`,
  },
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  marginTop: `${theme.spacing(3)} !important`,
}));

export const FieldsContainer = styled('div')(({ theme }) => ({
  marginTop: `${theme.spacing(5)} !important`,
}));

export const StyledTextField = styled(TextField)`
  width: 100%;
`;

export const FieldError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginBottom: `${theme.spacing(2)} !important`,
  marginTop: `${theme.spacing(1)} !important`,
}));

export const LocationContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const CityTextField = styled(StyledTextField)`
  width: 50%;
`;

export const StateTextField = styled(StyledTextField)`
  width: 20%;
  margin-top: 0 !important;
`;

export const ZipTextField = styled(StyledTextField)`
  width: 26%;
  marginTop: 0 !important;
`;

export const ErrorsContainer = styled('div')``;

export const SubmitWrapper = styled('div')`
  position: relative;
`;

export const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: `${theme.spacing(2)} !important`,
  width: '100%',
}));

export const ButtonProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -4;
  margin-left: -12;
`;

export const SignInText = styled(Typography)(({ theme }) => ({
  marginTop: `${theme.spacing(2)} !important`,
  color: theme.palette.text.secondary,
}));

export const SignInLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  '&:hover': {
    color: theme.palette.primary.main
  }
}));

const styles = (theme) => ({
  // root: {
  //   paddingLeft: '100px',
  //   paddingRight: '100px',
  //   paddingBottom: '75px',
  //   flexBasis: '700px',
  //   [theme.breakpoints.down('sm')]: {
  //     paddingLeft: `${theme.spacing(2)} !important`,
  //     paddingRight: `${theme.spacing(2)} !important`
  //   }
  // },
  // title: {
  //   marginTop: `${theme.spacing(3)} !important`
  // },
  // fields: {
  //   marginTop: `${theme.spacing(5)} !important`
  // },
  // textField: {
  //   width: '100%',
  //   // '& + & ': {
  //   //   marginTop: `${theme.spacing(2)} !important`
  //   // }
  // },
  policy: {
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-11px'
  },
  policyText: {
    display: 'inline',
    color: theme.palette.text.secondary
  },
  policyUrl: {
    color: theme.palette.text.primary,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main
    }
  },
  // submitWrapper: {
  //   position: 'relative'
  // },
  // submitButton: {
  //   marginTop: `${theme.spacing(2)} !important`,
  //   width: '100%'
  // },
  // buttonProgress: {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   marginTop: -4,
  //   marginLeft: -12
  // },
  // signIn: {
  //   marginTop: `${theme.spacing(2)} !important`,
  //   color: theme.palette.text.secondary
  // },
  // signInUrl: {
  //   color: theme.palette.primary.main,
  //   fontWeight: 'bold',
  //   '&:hover': {
  //     color: theme.palette.primary.main
  //   }
  // },
  // fieldError: {
  //   color: theme.palette.error.main,
  //   marginBottom: `${theme.spacing(2)} !important`,
  //   marginTop: `${theme.spacing(1)} !important`
  // },
  submitError: {
    color: theme.palette.error.main,
    alignText: 'center',
    marginBottom: `${theme.spacing(1)} !important`,
    marginTop: `${theme.spacing(2)} !important`
  },
  // errorsCont: {
  //   display: 'flex'
  // },
  // cityState: {
  //   display: 'flex',
  //   justifyContent: 'space-between'
  // },
  // city: {
  //   width: '50%'
  // },
  // state: {
  //   width: '20%',
  //   marginTop: '0 !important'
  // },
  // zip: {
  //   width: '26%',
  //   marginTop: '0 !important'
  // }
});

export default styles;
