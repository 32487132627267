import {
  styled,
  Typography,
  IconButton,
  AppBar
} from '@mui/material';

export const StyledAppBar = styled(AppBar)`
  box-shadow: none;
`;

export const LogoImg = styled('img')`
  max-width: 50px;
`;

export const LogoText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  marginLeft: `${theme.spacing(2)} !important`,
  whiteSpace: 'nowrap',
}));

export const Spacer = styled('div')`
  flex-grow: 1;
`;

export const RightButton = styled(IconButton)(({ theme }) => ({
  marginLeft: `${theme.spacing(1)} !important`
}));
