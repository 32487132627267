import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Sidebar from './Sidebar';
import Topbar from './Topbar';
import { Main, RootDiv, StyledFooter } from 'styles/layouts/AppLayout/AppLayout.style';

const AppLayout = ({ component: Component, ...rest }) => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const showSidebar = isDesktop ? true : openSidebar;

  return (
    <RootDiv shiftContent={isDesktop}>
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={showSidebar}
        isDesktop={isDesktop}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <Main>
        <Component {...rest} />
        <StyledFooter />
      </Main>
    </RootDiv>
  );
};

if (process.env.NODE_ENV !== 'production') {
  AppLayout.propTypes = {
    component: PropTypes.any.isRequired,
  };
}

export default AppLayout;
