import { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Typography, Grid, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import publicComponent from 'components/helpers/publicComponent';
import useFormState from 'utils/forms/useFormState';
import { attemptLogin } from '../../../actions/auth';
import {
  ButtonProgress,
  FieldError,
  RememberCheckbox,
  RememberContainer,
  RememberText,
  RootForm,
  SignInButton,
  SignInWrapper,
  StyledTextField,
  SubText,
  SubTextLink,
  TitleText,
} from 'styles/Login.style';

const LoginForm = ({ attemptLogin, isLoading }) => {
  // const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {
    state: { values, errors },
    handleError,
    handleChange,
  } = useFormState(
    {
      email: '',
      password: '',
      remember: true,
    },
    'email'
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    attemptLogin(values)
      .catch(handleError);
  };

  return (
    <RootForm noValidate onSubmit={handleSubmit}>
      <TitleText variant="h2">Sign in</TitleText>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <StyledTextField
            id="email"
            label="Email address"
            autoComplete="email"
            autoFocus
            required
            type="email"
            variant="outlined"
            onChange={handleChange}
            value={values.email}
          />
          <FieldError variant="body2">{errors.email}</FieldError>
        </Grid>
        <Grid item>
          <StyledTextField
            id="password"
            label="Password"
            autoComplete="current-password"
            required
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            onChange={handleChange}
            value={values.password}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
            }}
          />
          <FieldError variant="body2">{errors.password}</FieldError>
          <RememberContainer>
            <RememberCheckbox
              checked={values.remember}
              color="primary"
              id="remember"
              onChange={handleChange}
            />
            <RememberText variant="body1">Keep Me Signed In</RememberText>
          </RememberContainer>
        </Grid>
      </Grid>
      <SignInWrapper>
        <SignInButton
          type="submit"
          disabled={isLoading}
          onClick={handleSubmit}
          color="primary"
          size="large"
          variant="contained"
        >
          Sign In
        </SignInButton>
        {isLoading && <ButtonProgress size={24} />}
      </SignInWrapper>
      <Grid container justifyContent="space-between">
        {/* <Grid container item sm={5}>
          <SubText item>
            <Typography variant="body1">Forgot your password? </Typography>
            <Typography variant="body1">
              <SubTextLink to="/password-reset" component={NavLink}>
                Reset Password
              </SubTextLink>
            </Typography>
          </SubText>
        </Grid> */}
        {/* <Grid container item sm={5}>
          <SubText item>
            <Typography variant="body1">
              Don't have an account?{' '}
            </Typography>    
            <Typography variant="body1">
              <SubTextLink
                to="/register"
                component={NavLink}
              >
                Create Account
              </SubTextLink>
            </Typography>
          </SubText>
          <Grid item>
          </Grid>
        </Grid> */}
      </Grid>
    </RootForm>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
});

export default compose(
  connect(
    mapStateToProps,
    { attemptLogin }
  ),
  publicComponent
)(LoginForm);
