import React from 'react';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
// import InputIcon from '@material-ui/icons/Input';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitIcon from 'styles/Icons.style';

const pages = [
  {
    title: 'Dashboard',
    href: '/dashboard',
    icon: <AppsOutlinedIcon />,
    action:'DASHBOARD_VIEW',
  },
  {
    title: 'Settings',
    href: '/settings',
    icon: <SettingsIcon />,
    // mobileOnly: true,
    action:'DASHBOARD_VIEW',
  },
  {
    title: 'Sign Out',
    href: '/logout',
    icon: <ExitIcon />,
    // mobileOnly: true,
    action:'DASHBOARD_VIEW',
  },
];

export default pages;
